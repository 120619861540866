import { combineReducers } from 'redux';
import { urlsWrapper } from 'config';
import { serialize } from 'utils';
import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import {
  RESET_TYPES,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemConstants,
  createCommonItemReducer,
  saveData,
} from './utils';
import { commonGet } from './api';

const POLL_INTERVAL = 1000;
const POLL_COUNTS = 5;

const divisionWeatherAggregates = createCommonItemConstants(
  'DIVISION_WEATHER_AGGREGATES'
);
const divisionWeatherHistory = createCommonItemConstants(
  'DIVISION_WEATHER_HISTORY'
);
const divisionWeatherAlerts = createCommonListConstants(
  'DIVISION_WEATHER_ALERTS'
);
const weatherGeojson = createCommonItemConstants('DIVISION_WEATHER_GEOJSON');
const divisionWeatherCurrent = createCommonListConstants(
  'DIVISION_WEATHER_CURRENT'
);
const divisionWeatherForecast = createCommonListConstants(
  'DIVISION_WEATHER_FORECAST'
);
const fieldWeatherCurrent = createCommonListConstants('FIELD_WEATHER_CURRENT');
const fieldWeatherForecast = createCommonListConstants(
  'FIELD_WEATHER_FORECAST'
);
const fieldWeatherAggregates = createCommonItemConstants(
  'FIELD_WEATHER_AGGREGATES'
);
const fieldWeatherHistory = createCommonItemConstants('FIELD_WEATHER_HISTORY');
const fieldWeatherAlerts = createCommonListConstants('FIELD_WEATHER_ALERTS');
const divisionWeatherSources = createCommonListConstants(
  'DIVISION_WEATHER_SOURCE'
);
const fieldWeatherMultiyear = createCommonItemConstants(
  'FIELD_WEATHER_MULTIYEAR'
);
const divisionWeatherMultiyear = createCommonItemConstants(
  'DIVISION_WEATHER_MULTIYEAR'
);
const weatherAlertsError = createCommonItemConstants('WEATHER_ALERTS_ERROR');

// reducer
export default combineReducers({
  divisionWeatherCurrent: createCommonListReducer(divisionWeatherCurrent),
  divisionWeatherForecast: createCommonListReducer(divisionWeatherForecast),
  divisionWeatherAggregates: createCommonItemReducer(divisionWeatherAggregates),
  divisionWeatherHistory: createCommonItemReducer(divisionWeatherHistory),
  divisionWeatherAlerts: createCommonListReducer(divisionWeatherAlerts),
  fieldWeatherCurrent: createCommonListReducer(fieldWeatherCurrent),
  fieldWeatherForecast: createCommonListReducer(fieldWeatherForecast),
  fieldWeatherAggregates: createCommonItemReducer(fieldWeatherAggregates),
  fieldWeatherHistory: createCommonItemReducer(fieldWeatherHistory),
  fieldWeatherAlerts: createCommonListReducer(fieldWeatherAlerts),
  weatherGeojson: createCommonItemReducer(weatherGeojson),
  divisionWeatherSources: createCommonListReducer(divisionWeatherSources),
  weatherAlertsError: createCommonItemReducer(weatherAlertsError),
  fieldWeatherMultiyear: createCommonItemReducer(fieldWeatherMultiyear),
  divisionWeatherMultiyear: createCommonItemReducer(divisionWeatherMultiyear),
});

// Новая функция для получения многолетних данных о погоде для поля
export function getFieldWeatherMultiyear(fieldId, params, sourceId) {
  return saveData(
    `${urlsWrapper(
      'fields'
    )}/${fieldId}/weather/${sourceId}/multiyear?${serialize(params)}`,
    fieldWeatherMultiyear.SET_ITEM,
    fieldWeatherMultiyear.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

// Новая функция для получения многолетних данных о погоде для хозяйства
export function getDivisionWeatherMultiyear(divisionId, params, sourceId) {
  return saveData(
    `${urlsWrapper(
      'divisions'
    )}/${divisionId}/weather/${sourceId}/multiyear?${serialize(params)}`,
    divisionWeatherMultiyear.SET_ITEM,
    divisionWeatherMultiyear.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

// actions
export function getDivisionWeatherCurrent(divisionId, sourceId) {
  return saveData(
    `${urlsWrapper('divisions')}/${divisionId}/weather/${sourceId}/current`,
    divisionWeatherCurrent.SET_LIST,
    divisionWeatherCurrent.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

export function getDivisionWeatherForecast(divisionId, sourceId) {
  return saveData(
    `${urlsWrapper('divisions')}/${divisionId}/weather/${sourceId}/forecast`,
    divisionWeatherForecast.SET_LIST,
    divisionWeatherForecast.SET_IS_PENDING,
    RESET_TYPES.LIST
  );
}

export function getFieldWeatherCurrent(fieldId, sourceId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}/weather/${sourceId}/current`,
    fieldWeatherCurrent.SET_LIST,
    fieldWeatherCurrent.SET_IS_PENDING
  );
}

export function getFieldWeatherForecast(fieldId, sourceId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}/weather/${sourceId}/forecast`,
    fieldWeatherForecast.SET_LIST,
    fieldWeatherForecast.SET_IS_PENDING
  );
}

export function getDivisionWeatherAggregates(divisionId, params, sourceId) {
  return saveData(
    `${urlsWrapper(
      'divisions'
    )}/${divisionId}/weather/${sourceId}/aggregate?${serialize(params)}`,
    divisionWeatherAggregates.SET_ITEM,
    divisionWeatherAggregates.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

export function getDivisionWeatherAggregatesWithoutSave({
  divisionId,
  params,
  sourceId,
}) {
  return commonGet({
    url: `${urlsWrapper(
      'divisions'
    )}/${divisionId}/weather/${sourceId}/aggregate?${serialize(params)}`,
  });
}

export function getDivisionWeatherHistory(divisionId, params, sourceId) {
  return saveData(
    `${urlsWrapper(
      'divisions'
    )}/${divisionId}/weather/${sourceId}/history?${serialize(params)}`,
    divisionWeatherHistory.SET_ITEM,
    divisionWeatherHistory.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

export function getFieldWeatherAggregates(fieldId, params, sourceId) {
  return saveData(
    `${urlsWrapper(
      'fields'
    )}/${fieldId}/weather/${sourceId}/aggregate?${serialize(params)}`,
    fieldWeatherAggregates.SET_ITEM,
    fieldWeatherAggregates.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

export function getFieldWeatherAggregatesWithoutSave({
  fieldId,
  params,
  sourceId,
}) {
  return commonGet({
    url: `${urlsWrapper(
      'fields'
    )}/${fieldId}/weather/${sourceId}/aggregate?${serialize(params)}`,
  });
}

export function getFieldWeatherHistory(fieldId, params, sourceId) {
  return saveData(
    `${urlsWrapper(
      'fields'
    )}/${fieldId}/weather/${sourceId}/history?${serialize(params)}`,
    fieldWeatherHistory.SET_ITEM,
    fieldWeatherHistory.SET_IS_PENDING
  );
}

export function getWeatherGeojson() {
  return saveData(
    `${urlsWrapper('weatherGeojson')}`,
    weatherGeojson.SET_ITEM,
    weatherGeojson.SET_IS_PENDING
  );
}

let interval;
let intervalCounter = 0;
export const stopPollingWeatherAlerts = (setIsPending) => (dispatch) => {
  clearInterval(interval);
  dispatch(setIsPending(false));
};

export const setWeatherAlertsError = (error) => (dispatch) => {
  dispatch(createAction(weatherAlertsError.SET_ITEM)(error));
};

export const getWeatherAlerts = (url, setItem, setIsPending) => async (
  dispatch
) => {
  clearInterval(interval);
  dispatch(setIsPending(true));
  dispatch(setItem([]));
  dispatch(setWeatherAlertsError(null));

  const intervalStatusCheck = async () => {
    // eslint-disable-next-line no-plusplus
    intervalCounter++;
    try {
      const { data } = await commonGet({
        url,
      });
      dispatch(setItem(data));
      dispatch(stopPollingWeatherAlerts(setIsPending));
      intervalCounter = 0;
    } catch (error) {
      dispatch(stopPollingWeatherAlerts(setIsPending));
      const err =
        error && error.errors && error.errors.err
          ? error.errors.err
          : { status: null };
      if (err.status === 429) {
        interval = setInterval(intervalStatusCheck, POLL_INTERVAL);
      }
      if (intervalCounter > POLL_COUNTS) {
        dispatch(stopPollingWeatherAlerts(setIsPending));
        intervalCounter = 0;
        dispatch(setWeatherAlertsError(err));
      }
    }
  };
  await intervalStatusCheck();
};

export function getDivisionWeatherAlerts(divisionId, params, sourceId) {
  return getWeatherAlerts(
    `${urlsWrapper(
      'divisions'
    )}/${divisionId}/weather/${sourceId}/alerts?${serialize(params)}`,
    createAction(divisionWeatherAlerts.SET_LIST),
    createAction(divisionWeatherAlerts.SET_IS_PENDING)
  );
}

export function getFieldWeatherAlerts(fieldId, params, sourceId) {
  return getWeatherAlerts(
    `${urlsWrapper('fields')}/${fieldId}/weather/${sourceId}/alerts?${serialize(
      params
    )}`,
    createAction(fieldWeatherAlerts.SET_LIST),
    createAction(fieldWeatherAlerts.SET_IS_PENDING)
  );
}

export function getWeatherSources() {
  return saveData(
    `${urlsWrapper('weatherSources')}`,
    divisionWeatherSources.SET_LIST,
    divisionWeatherSources.SET_IS_PENDING
  );
}

export function getDivisionWeatherSources(divisionId) {
  return saveData(
    `${urlsWrapper('divisions')}/${divisionId}/weather/sources`,
    divisionWeatherSources.SET_LIST,
    divisionWeatherSources.SET_IS_PENDING
  );
}

export async function getFieldWeatherSources(fieldId) {
  const { data } = await commonGet({
    url: `${urlsWrapper('fields')}/${fieldId}/weather/sources`,
  }).catch(() => []);
  return data;
}

// selectors
export const getWeatherGeojsonSelector = createSelector(
  [(state) => state.weather.weatherGeojson],
  (geojson) => geojson
);

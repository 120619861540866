import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import auth, { auth as authActions } from 'modules/auth';
import cropDevStages from 'modules/cropDevStages';
import { crops, cropItem, cropParams, cropColorSettings } from 'modules/crops';
import equipments, { equipmentType } from 'modules/equipments';
import customers from 'modules/customers';
import currentAdvisorCustomer from 'modules/currentAdvisorCustomer';
import { diseases, diseaseItem } from 'modules/diseases';
import divisions from 'modules/divisions';
import divisionGroups from 'modules/divisionGroups';
import divisionsByCustomer from 'modules/divisionsByCustomer';
import fertilizers from 'modules/fertilizers';
import field from 'modules/field';
import fields from 'modules/fields';
import markers from 'modules/markers';
import globalFilters from 'modules/globalFilters';
import mapbox from 'modules/mapbox';
import pesticides from 'modules/pesticides';
import { pests, pestItem } from 'modules/pests';
import scouts from 'modules/scouts';
import seeds from 'modules/seeds';
import mechanizers from 'modules/mechanizers';
import controlWorkrecords from 'modules/controlWorkrecords';
// import gps from 'modules/gps';
import soilTypes from 'modules/soilTypes';
import tillageTypes from 'modules/tillageTypes';
import { users, allowedUsersInfo } from 'modules/users';
import weather from 'modules/weather';
import { weeds, weedItem } from 'modules/weeds';
import workRecords from 'modules/workRecords';
import workTypes from 'modules/workTypes';
import advisorUsers from 'modules/advisorUsers';
import userInfo from 'modules/userInfo';
import comments from 'modules/comments';
import notifications from 'modules/notifications';
import topics from 'modules/topics';
import trackers from 'modules/trackers';
import analytics from 'modules/analytics';
import scoutFilters from 'modules/scoutFilters';
import { visitProposes } from 'modules/visits';
import plots from 'modules/plots';
import profile from 'modules/profile';
import mapFilters from 'modules/mapFilters';
import documents from 'modules/documents';
import nutritionproblems from 'modules/nutritionproblems';
import workproblems from 'modules/workproblems';
import weatherproblems from 'modules/weatherproblems';
import advisors from 'modules/advisors';
import satDivisionScenes from 'modules/satDivisionScenes';
import satProductsByDivision from 'modules/satProductsByDivision';
import satFieldScenes from 'modules/satFieldScenes';
import satProductsByField from 'modules/satProductsByField';
import satProductsChecking from 'modules/satProductsChecking';
import seasonPlans from 'modules/seasonPlans';
import advisorAnalytics from 'modules/advisorAnalytics';
import reports from 'modules/reports';
import { regions, bayerRegions } from 'modules/regions';
import districts from 'modules/districts';
import works from 'modules/works';
import globalWorkrecords from 'modules/globalWorkrecords';
import {
  unattachedTracks,
  unattachedTracksGeojson,
} from 'modules/unattachedTracks';
import { machines } from 'modules/machines';
import { gpsItems } from 'modules/gps';
import { cadasterParcels } from 'modules/cadastralParcels';
import terrasoftTrial from 'modules/terrasofttrial';

const appReducers = combineReducers({
  auth,
  cropDevStages,
  crops,
  cropParams,
  cropItem,
  customers,
  currentAdvisorCustomer,
  diseases,
  diseaseItem,
  divisions,
  divisionGroups,
  divisionsByCustomer,
  gpsItems,
  cadasterParcels,
  fertilizers,
  field,
  fields,
  markers,
  globalFilters,
  mapbox,
  pesticides,
  pests,
  pestItem,
  scouts,
  seeds,
  soilTypes,
  tillageTypes,
  users,
  advisorUsers,
  userInfo,
  weather,
  // gps,
  weeds,
  weedItem,
  trackers,
  workRecords,
  workTypes,
  comments,
  notifications,
  topics,
  analytics,
  scoutFilters,
  plots,
  profile,
  mapFilters,
  documents,
  nutritionproblems,
  workproblems,
  allowedUsersInfo,
  weatherproblems,
  advisors,
  satDivisionScenes,
  satProductsByDivision,
  satFieldScenes,
  satProductsByField,
  satProductsChecking,
  form: formReducer,
  seasonPlans,
  advisorAnalytics,
  reports,
  regions,
  equipmentType,
  bayerRegions,
  districts,
  works,
  visitProposes,
  globalWorkrecords,
  unattachedTracks,
  unattachedTracksGeojson,
  machines,
  cropColorSettings,
  equipments,
  mechanizers,
  terrasoftTrial,
  controlWorkrecords,
});

export default (state, action) => {
  if (action.type === authActions.SET_LOGOUT) {
    return appReducers({}, action); // CLEAR ALL
  }

  return appReducers(state, action);
};

import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { commonDelete, commonGet, commonPost, commonPut } from './api';

const itemConstants = {
  SET_HOVER_ITEM: 'CADASTER_PARCELS_SET_HOVER_ITEM',
  SET_TOTAL_COUNT: 'CADASTER_PARCELS_SET_TOTAL_COUNT',
};

// reducer
export const cadasterParcels = (
  state = {
    hoveredItem: null,
    totalFields: 0,
    totalArea: 0,
  },
  action
) => {
  switch (action.type) {
    case itemConstants.SET_HOVER_ITEM:
      return {
        ...state,
        hoveredItem: action.payload,
      };
    case itemConstants.SET_TOTAL_COUNT:
      return {
        ...state,
        totalFields: action.payload.totalFields,
        totalArea: action.payload.totalArea,
      };
    default:
      return state;
  }
};

export const setIsHoverItem = (payload) => ({
  type: itemConstants.SET_HOVER_ITEM,
  payload,
});

export const setTotalCount = (payload) => ({
  type: itemConstants.SET_TOTAL_COUNT,
  payload,
});

export const getHoveredCadasterItem = createSelector(
  [(state) => state.cadasterParcels.hoveredItem],
  (hoveredItem) => hoveredItem
);

export const getHoveredCadasterInfo = createSelector(
  [
    (state) => state.cadasterParcels.totalFields,
    (state) => state.cadasterParcels.totalArea,
  ],
  (totalFields, totalArea) => ({ totalFields, totalArea })
);

export function getCadastralParcels() {
  return commonGet({
    url: urlsWrapper('cadastralParcels'),
  });
}

export function createCadastralParcel(data) {
  return commonPost({
    url: `${urlsWrapper('cadastralParcels')}`,
    data,
    name: 'кадастровый участок',
  });
}

export function updateCadastralParcel(parcelId, data) {
  return commonPut({
    url: `${urlsWrapper('cadastralParcels')}/${parcelId}`,
    data,
    name: 'кадастровый участок',
  });
}

export function deleteCadastralParcel(parcelId, data) {
  return commonDelete({
    url: `${urlsWrapper('cadastralParcels')}/${parcelId}`,
    data,
    name: 'кадастровый участок',
  });
}

export function getCadastralParcelsGeojson() {
  return commonGet({
    url: `${urlsWrapper('cadastralParcels')}/geojson`,
  });
}

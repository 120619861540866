import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import moment from 'moment';
import { serialize } from 'utils';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemConstants,
  createCommonItemReducer,
} from './utils';

export const FORM_NAME = 'ControlWorksForm';

const constantsControlWorkrecords = createCommonListConstants(
  'CONTROL_WORK_RECORDS'
);

const contstantsControlWorkrecordsSearchText = createCommonItemConstants(
  'CONTROL_WORK_RECORDS_SEARCH_TEXT'
);

export default combineReducers({
  workrecords: createCommonListReducer(constantsControlWorkrecords),
  searchText: createCommonItemReducer(contstantsControlWorkrecordsSearchText),
});

export function getControlWorkRecords(params) {
  return saveData(
    `${urlsWrapper('workrecords')}?${serialize(params)}`,
    constantsControlWorkrecords.SET_LIST,
    constantsControlWorkrecords.SET_IS_PENDING
  );
}

export const getControlWorkRecordsSelector = createSelector(
  [
    (state) => state.controlWorkrecords.workrecords.list,
    (state) => state.controlWorkrecords.workrecords.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const setSearchText = (payload) => ({
  type: contstantsControlWorkrecordsSearchText.SET_ITEM,
  payload,
});

export const getSearchTextSelector = createSelector(
  [(state) => state.controlWorkrecords.searchText.item],
  (searchText) => searchText
);

export const getControlWorksFormSelector = createSelector(
  [(state) => state.form?.[FORM_NAME]?.values],
  (value) => value
);

export const getControlWorksFormDateSelector = createSelector(
  [getControlWorksFormSelector],
  (formValues) => formValues?.date
);

export const getControlWorksFormSeasonSelector = createSelector(
  [getControlWorksFormDateSelector],
  (formValues) =>
    moment(formValues?.date).isValid()
      ? moment(formValues?.date).year()
      : moment().year()
);

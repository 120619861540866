import React, { memo, useEffect } from 'react';
import Chatra from '@chatra/chatra';

const Support = () => {
  const onOpenYoutube = () => {
    window.open(
      'https://agromon.ru/education',
      '_blank',
      'noopener noreferrer'
    );
  };

  useEffect(() => {
    Chatra('pageView');
  }, []);

  return (
    <div className="support">
      <div className="support__text" style={{ fontSize: '14px' }}>
        <div
          onClick={onOpenYoutube}
          role="button"
          tabIndex={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '6px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          <svg
            style={{ width: '20px' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
          </svg>
          Обучающие видео
        </div>
        <div id="chatra-wrapper" />
        <div>
          <div
            role="button"
            tabIndex={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              marginBottom: '6px',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              Chatra('show');
              Chatra('openChat', true);
            }}
          >
            <svg
              width="18"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M329.903 0C302.72 0.17 278.164 7.435 256.267 21.823L256 22L254.959 21.319C232.999 7.098 208.382 0 181.136 0C80.941 0 0 77.79 0 175.591C0 222.695 15.436 260.499 52.904 303.732C78.213 332.934 71.705 370.331 36.928 400.856L5.173 428.729L46.838 421.708C90.943 414.276 127.827 397.579 157.354 371.58C158.861 370.253 161.05 370.047 162.778 371.068L240.136 416.812C250.087 422.696 262.339 423.152 272.699 418.023C431.28 339.519 512 258.809 512 175.591C512 77.79 431.058 0 330.864 0H329.903ZM330.864 24C418.012 24 488 91.263 488 175.591C488 246.913 413.227 321.676 262.052 396.514C258.965 398.042 255.316 397.906 252.352 396.153L174.993 350.41C164.448 344.174 151.121 345.344 141.828 353.278L140.71 354.253C123.17 369.509 102.617 381.127 78.935 389.104L78.093 389.384L78.261 389.116C98.555 355.954 97.459 318.497 71.04 288.013C37.198 248.965 24 216.64 24 175.591C24 91.262 93.987 24 181.136 24C204.336 24 224.831 30.064 243.192 42.304L256 50.843L268.809 42.304C287.169 30.064 307.663 24 330.864 24Z" />
                <circle cx="145" cy="206" r="30" />
                <circle cx="367" cy="206" r="30" />
                <circle cx="256" cy="206" r="30" />
              </g>
            </svg>
            Задать вопрос в чате
          </div>
        </div>
        <div>
          Также по любым вопросам обращайтесь по телефону
          <div>8 (800) 250-28-77 или почте support@agromon.ru</div>
        </div>
      </div>
    </div>
  );
};

export default memo(Support);

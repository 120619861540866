import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DivisionGroups from 'components/common/DivisionGroups';
import { getDivisions } from 'utils/divisionGroups';

const DivisionsDropdown = ({
  divisions,
  divisionGroups,
  value,
  handleChange,
  isSelect,
  className,
}) => {
  const { title, divisionsOptions, id } = useMemo(
    () =>
      getDivisions({
        divisions,
        divisionGroups,
        value,
        isSelect,
      }),
    [divisions, divisionGroups, value, isSelect]
  );

  useEffect(() => {
    if (id === null) {
      handleChange();
    }
  }, [id]);

  return (
    <div>
      <DropdownButton
        title={
          <span
            style={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...(divisionsOptions?.length ? { maxWidth: '93%' } : {}),
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            {title}
          </span>
        }
        variant="none"
        bsPrefix={classNames(
          className,
          !divisionsOptions?.length ? 'divisions-dropdown_no-caret' : ''
        )}
        id="divisions-dropdown"
      >
        {divisionsOptions.map((division) => (
          <Dropdown.Item eventKey={division.id} key={division.id}>
            <DivisionGroups division={division} handleChange={handleChange} />
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default React.memo(DivisionsDropdown);
